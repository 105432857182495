<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'organization:user:list:button:delete'"
        >删除</a-button
      >
      <a-button class="mr-3" @click="recycleBinVisible = true" icon="hdd" v-has="'organization:list:button:recyclebin'"
        >回收站</a-button
      >
      <a-button
        class="mr-3"
        icon="close-circle"
        v-if="selectedRowKeys.length"
        @click="offUser"
        v-has="'organization:user:list:button:add'"
        >停用</a-button
      >
      <a-button
        class="mr-3"
        type="primary"
        v-if="selectedRowKeys.length"
        icon="check-circle"
        @click="openUser"
        v-has="'organization:user:list:button:add'"
        >启用</a-button
      >
      <a-button type="primary" icon="plus" @click="openAddForm" v-has="'organization:user:list:button:add'"
        >新建</a-button
      >
    </div>
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper" slot="search" v-has="'organization:user:list:button:search'">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input
              placeholder="输入昵称查找"
              :disabled="$refs.newTable ? $refs.newTable.tableLoading : true"
              allow-clear
              v-model="queryParam.nickName"
              @pressEnter="searchQuery"
            >
            </a-input>
          </a-col>
          <a-col>
            <a-button
              type="primary"
              icon="search"
              :disabled="$refs.newTable ? $refs.newTable.tableLoading : true"
              @click="searchQuery"
              >搜索</a-button
            >
          </a-col>
        </a-row>
      </a-form>
    </div>
    <user-table ref="newTable" @setRowkeys="setRowkeys" @openModal="openModal" :queryParamProp="queryParam" />
    <!-- <v-table ref='newTable' :height="'80'" :tableType="'userTable'" :queryParamProp='queryParam' :selectionRowsProp='selectionRows' :selectedRowKeysprop="selectedRowKeys" :url="url" @openModal="openModal" :columns="columns" @setRowkeys="setRowkeys" /> -->
    <user-modal
      ref="drawer"
      @openCropper="openCropper"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    ></user-modal>
    <!-- 用户回收站 -->
    <user-recycle-bin-modal :visible.sync="recycleBinVisible" @ok="modalFormOk" />
    <user-cropper-model ref="userCropperModelNew" @upImg="upImg"></user-cropper-model>
  </body-layout>
</template>

<script>
/* import UserModal from './modules/UserModal' */
import UserModal from './modules/UserModalNew'
import PasswordModal from './modules/PasswordModal'
import { getAction, putAction, getFileAccessHttpUrl } from '@/api/manage'
import { frozenBatch } from '@/api/api'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import JInput from '@/components/jeecg/JInput'
import UserRecycleBinModal from './modules/UserRecycleBinModal'
import JSuperQuery from '@/components/jeecg/JSuperQuery'
/* import vTable from '@/components/v-table/vTable' */
import userTable from '@/components/user-table'
import userCropperModel from './modules/cropper-model.vue'
import { checkPermission } from '@/utils/hasPermission'
import { citys } from './modules/city.js'
import { postAction } from '../../api/manage'
export default {
  name: 'UserList',
  mixins: [JeecgListMixin],
  components: {
    UserModal,
    PasswordModal,
    JInput,
    UserRecycleBinModal,
    JSuperQuery,
    userCropperModel,
    userTable
  },
  data() {
    return {
      description: '这是用户管理页面',
      queryParam: {},
      recycleBinVisible: false,
      columns: [
        {
          title: '昵称',
          dataIndex: 'nickName',
          scopedSlots: { customRender: 'nickName' },
          width: 160,
          sorter: true,
          checked: true,
          isNum: false
        },
        {
          title: '所属部门',
          width: 200,
          scopedSlots: { customRender: 'departs' },
          dataIndex: 'departs',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          width: 160,
          scopedSlots: { customRender: 'positions' },
          dataIndex: 'positions',
          checked: true,
          isNum: false
        },
        /* {
          title: '在线状态',
          width: 80,
          scopedSlots: { customRender: 'onlineType' },
          dataIndex: 'onlineType',
        }, */
        {
          title: '聊天工具',
          width: 160,
          dataIndex: 'userFile.qq',
          scopedSlots: { customRender: 'qq' },
          checked: true,
          isNum: false
        },
        {
          title: '工作地',
          width: 160,
          scopedSlots: { customRender: 'workCity' },
          dataIndex: 'userFile.workCity',
          checked: true,
          isNum: false
        },
        {
          title: '签约类型',
          width: 120,
          dataIndex: 'userFile.contractType',
          checked: true,
          isNum: false
        },
        {
          title: '可工作日',
          width: 220,
          dataIndex: 'userFile.workDays',
          scopedSlots: { customRender: 'workDays' },
          checked: true,
          isNum: false
        },
        {
          title: '周可安排数量',
          width: 150,
          dataIndex: 'weekAvailableCount',
          scopedSlots: { customRender: 'weekAvailableCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周已安排数量',
          width: 150,
          dataIndex: 'weekTaskCount',
          scopedSlots: { customRender: 'weekTaskCount' },
          checked: true,
          isNum: true
        },
        {
          title: '周空余数量',
          width: 140,
          dataIndex: 'userFile.weekFreeCount',
          scopedSlots: { customRender: 'weekFreeCount' },
          checked: true,
          isNum: true
        },
        {
          title: '擅长作品类型',
          width: 180,
          scopedSlots: { customRender: 'goodAtProductionType' },
          dataIndex: 'userFile.goodAtProductionType',
          checked: true,
          isNum: false
        },
        {
          title: '抵触作品类型',
          width: 180,
          scopedSlots: { customRender: 'badAtProductionType' },
          dataIndex: 'userFile.badAtProductionType',
          checked: true,
          isNum: false
        }
        /*         {
          title: '工作台账号',
          width: 100,
          dataIndex: 'onlyTool',
          scopedSlots: { customRender: 'onlyTool' },
        }, */
      ],
      superQueryFieldList: [
        { type: 'input', value: 'username', text: '用户账号' },
        { type: 'input', value: 'realname', text: '用户姓名' },
        { type: 'select', value: 'sex', text: '性别', dictCode: 'sex' }
      ],
      curRow: { userFile: {} },
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/sys/user/list',
        delete: '/sys/user/delete',
        deleteBatch: '/sys/user/deleteBatch',
        exportXlsUrl: '/sys/user/exportXls',
        importExcelUrl: 'sys/user/importExcel'
      }
    }
  },
  computed: {
    importExcelUrl: function() {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    }
  },
  methods: {
    searchQuery() {
      if (this.$refs.newTable) {
        if(!this.queryParam.nickName) this.queryParam.nickName =''
        this.$refs.newTable.searchQuery()
      }
    },
    loadData() {},
    async openUser() {
      const res = await postAction('/sys/user/enableSysUser', {
        userIds: this.selectedRowKeys,
        enabled: 0
      })
      if (res.code == '200') {
        this.$message.success('启用成功')
        this.$refs.newTable.loadData()
      } else {
        this.$message.error(res.message)
      }
    },
    async offUser() {
      const res = await postAction('/sys/user/enableSysUser', {
        userIds: this.selectedRowKeys,
        enabled: 1
      })
      if (res.code == '200') {
        this.$message.success('停用成功')
        this.$refs.newTable.loadData()
      } else {
        this.$message.error(res.message)
      }
    },
    openModal(record) {
      if (this.checkPermission()) {
        this.openDetail(record)
      }
    },
    setRowkeys(records) {
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    checkPermission() {
      return checkPermission('user:view')
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        data = data.split(',')
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    },
    upImg(file) {
      this.$refs.drawer.upImg(file)
    },
    openCropper(url) {
      this.$refs.userCropperModelNew.openModal(url)
    },
    getAvatarView: function(avatar) {
      return getFileAccessHttpUrl(avatar)
    },

    batchFrozen: function(status) {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return false
      } else {
        let ids = ''
        let that = this
        let isAdmin = false
        that.selectionRows.forEach(function(row) {
          if (row.username == 'admin') {
            isAdmin = true
          }
        })
        if (isAdmin) {
          that.$message.warning('管理员账号不允许此操作,请重新选择！')
          return
        }
        that.selectedRowKeys.forEach(function(val) {
          ids += val + ','
        })
        that.$confirm({
          title: '确认操作',
          content: '是否' + (status == 1 ? '解冻' : '冻结') + '选中账号?',
          onOk: function() {
            frozenBatch({ ids: ids, status: status }).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.loadData()
                that.onClearSelected()
              } else {
                that.$message.warning(res.message)
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-table-thead .ant-table-row-cell-last {
  width: 120px !important;
}
.userInput-icon {
  color: rgba(0, 0, 0, 0.25);
  opacity: 0;
}
.userInput-icon:hover {
  opacity: 1;
  cursor: pointer;
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/deep/ .border-bottom {
  border: none !important;
}
</style>
